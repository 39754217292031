html,
body,
#root,
#app > div {
    height: 100%;
    font-family: 'Telegraf', sans-serif;
}

button {
    outline: none !important;
}

a {
    color: inherit;
    text-decoration: inherit;
}

.app-loader {
    z-index: 9999;
    position: fixed;
    top: 0;
    width: 100%;
    height: 2px;
}
/*
::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background: #673FFA;
    height: 18px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: local('Inter'), url(./resources/fonts/Inter-Regular.woff) format('opentype');
}
@font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: local('Inter'), url(./resources/fonts/Inter-Medium.woff) format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: local('Inter'), url(./resources/fonts/Roboto-Regular.ttf) format('opentype');
}
@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: local('Inter'), url(./resources/fonts/Roboto-Medium.ttf) format('opentype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: local('Poppins'), url(./resources/fonts/Poppins-Regular.ttf) format('opentype');
}
@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: local('Poppins'), url(./resources/fonts/Poppins-Medium.ttf) format('opentype');
}
